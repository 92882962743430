import { Grid, makeStyles, useTheme } from "@material-ui/core"
import React from "react"
import Card, { iCardProps } from "./Card"

interface iCardListProps {
  cardList?: iCardProps[]
}

const CardList: React.FC<iCardListProps> = props => {
  const theme = useTheme()
  const classes = makeStyles({
    root: {
      display: "flex",
      flexFlow: "row wrap",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
  })()
  return (
    <Grid container spacing={3} justify="center" alignItems="stretch">
      {props.cardList &&
        props.cardList.map((c, i) => {
          return (
            <Grid item key={`card-${i}`} sm={4} xs={12}>
              <Card
                title={c.title}
                content={c.content}
                imageData={c.imageData}
                imageName={c.imageName}
              />
            </Grid>
          )
        })}
    </Grid>
  )
}

export default CardList
