import {
  CardContent,
  CardHeader,
  Typography,
  Card as MuiCard,
  useTheme,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core"
import { IGatsbyImageData, StaticImage, GatsbyImage } from "gatsby-plugin-image"
import React from "react"

export interface iCardProps {
  title?: string
  content?: string
  imageName?: string
  imageData?: IGatsbyImageData
}

const Card: React.FC<iCardProps> = props => {
  const theme = useTheme()
  const classes = makeStyles({
    title: {
      marginBottom: theme.spacing(1),
    },
  })()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <MuiCard
      style={{ height: "100%" }}
      elevation={isSmallScreen ? 0 : undefined}
    >
      {props.imageData && (
        <GatsbyImage
          image={props.imageData}
          alt={props.imageName || "card image"}
          objectFit="contain"
        />
      )}
      {/* <StaticImage src="../../images/card_images/card1.jpg" alt="tree picture" placeholder="blurred" /> */}
      <CardContent>
        <Typography variant="h5" className={classes.title}>
          {props.title}
        </Typography>
        <Typography variant="body1">{props.content}</Typography>
      </CardContent>
    </MuiCard>
  )
}

export default Card
