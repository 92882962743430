import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { Grid, makeStyles, useTheme } from "@material-ui/core"
import CardList from "../components/cardList/CardList"
import { graphql } from "gatsby"
import { iCardProps } from "../components/cardList/Card"
import { getImage } from "gatsby-plugin-image"

interface iAboutUsDataResult {
  allFile?: {
    edges?: {
      node?: {
        name: string
        relativePath: string
        childImageSharp: any
      }
    }[]
  }
}

const AboutUsPage = ({ data }: { data: iAboutUsDataResult }) => {
  const theme = useTheme()
  const classes = makeStyles({})()

  console.log(data)
  const cardData: iCardProps[] = [
    {
      title: "Spiritual",
      imageName: "children-sunset",
      content:
        "We start every week and every day off with what we need most for our souls – a lesson from God’s Word. Our curriculum is Christian and the Word of God is foundational.",
    },
    {
      title: "Academics",
      imageName: "student-writing-test",
      content:
        "Our A.C.E. program is of the highest standard and every learner is individually paced through a variety of subjects. The A.C.E. learning material is tried and trusted and used throughout the world to teach and equip learners for tertiary education and for life.",
    },
    {
      title: "Social",
      imageName: "children-eating-lunch-outside",
      content:
        "Our learners’ social development is important and a part of balanced education. Facilitation of character development, relational abilities and societal contribution are imperative as we aim to help our learners to become well-balanced citizens.",
    },
    {
      title: "Family",
      imageName: "parents-day",
      content:
        "We believe that families are the cornerstones of society. Healthy, strong families keep children safe and optimizes learning. Regular and continuous communication with parents fosters an atmosphere of trust and care wherein our children can thrive.",
    },
    {
      title: "Fun",
      imageName: "kids-camping",
      content:
        "All work and no play makes for dull and poor learning. We understand that our children also need to know that learning can be fun. We organize several excursions as well as our annual school camp to round off the year.",
    },
  ]

  cardData.forEach(card => {
    const node = data.allFile?.edges?.find(e => e.node?.name == card.imageName)
    if (node && node.node && node.node.childImageSharp) {
      card.imageData = getImage(node.node.childImageSharp)
    }
  })

  return (
    <Layout>
      <Seo title="About Us" />
      <CardList cardList={cardData} />
    </Layout>
  )
}

export default AboutUsPage

export const aboutUsQuery = graphql`
  query AboutUsQuery {
    allFile(
      filter: { extension: { eq: "jpg" }, relativeDirectory: { eq: "school" } }
    ) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 1280
              formats: AUTO
              transformOptions: { cropFocus: ENTROPY }
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`
